import React from "react";
import { Logo } from "components";
import { FaHome } from "react-icons/fa";
import { SiWebcomponentsdotorg } from "react-icons/si";
import { AiFillSetting } from "react-icons/ai";
import Listelement from "../ListElement";

export default function Sidebar({ setSidebar, sidebar }) {
    return (
        <aside
            aria-label="Sidebar"
            className={`${
                sidebar ? "" : "hidden"
            } w-64 lg:block fixed top-0 left-0 h-screen overflow-y-auto bg-white shadow text-left px-5 pb-5 z-30`}
        >
            <Logo />

            <div className="py-4 overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
                <ul className="space-y-2">
                    <Listelement
                        onClick={() => setSidebar("dashboard")}
                        containerClassName="py-2 px-2 text-base font-normal text-gray-900 align-middle rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                        buttonClassName={`flex items-center focus:outline-none hover:text-blue-400 animate whitespace-no-wrap ${
                            sidebar === "dashboard" ? "text-blue-400" : ""
                        }`}
                    >
                        <FaHome />
                        <span className="ml-3">Dashboard</span>
                    </Listelement>
                    <Listelement
                        onClick={() => setSidebar("setting")}
                        buttonClassName={`flex items-center focus:outline-none hover:text-blue-400 animate whitespace-no-wrap ${
                            sidebar === "setting" ? "text-blue-400" : ""
                        }`}
                    >
                        <AiFillSetting />
                        <span className="ml-3">Setting</span>
                    </Listelement>
                    <Listelement
                        onClick={() => setSidebar("components")}
                        buttonClassName={`flex items-center focus:outline-none hover:text-blue-400 animate whitespace-no-wrap ${
                            sidebar === "components" ? "text-blue-400" : ""
                        }`}
                    >
                        <SiWebcomponentsdotorg />
                        <span className="ml-3">Air Components</span>
                    </Listelement>
                    <Listelement
                        isExpanded={true}
                        onClick={() => setSidebar("analytics")}
                        buttonClassName={`flex items-center focus:outline-none hover:text-blue-400 animate hover:cursor-pointer whitespace-no-wrap ${
                            sidebar === "analytics" ? "text-blue-400" : ""
                        }`}
                        listExpandedElems={[
                            {
                                nameElm: "PlacementSettings",
                                onClick: () => setSidebar("placementSettings"),
                                buttonClass:
                                    "w-full flex items-center rounded-lg focus:outline-none hover:text-blue-400 hover:bg-gray-100 px-4 py-2 animate whitespace-no-wrap text-sm",
                            },
                            {
                                nameElm: "Labels",
                                onClick: () => setSidebar("labels"),
                                buttonClass:
                                    "w-full flex items-center rounded-lg focus:outline-none hover:text-blue-400 hover:bg-gray-100 px-4 py-2 animate whitespace-no-wrap text-sm",
                            },
                            {
                                nameElm: "Config",
                                onClick: () => setSidebar("config"),
                                buttonClass:
                                    "w-full flex items-center rounded-lg focus:outline-none hover:text-blue-400 hover:bg-gray-100 px-4 py-2 animate whitespace-no-wrap text-sm",
                            },
                            {
                                nameElm: "SSR",
                                onClick: () => setSidebar("ssr"),
                                buttonClass:
                                    "w-full flex items-center rounded-lg focus:outline-none hover:text-blue-400 hover:bg-gray-100 px-4 py-2 animate whitespace-no-wrap text-sm",
                            },
                            {
                                nameElm: "Settings",
                                onClick: () => setSidebar("settings"),
                                buttonClass:
                                    "w-full flex items-center rounded-lg focus:outline-none hover:text-blue-400 hover:bg-gray-100 px-4 py-2 animate whitespace-no-wrap text-sm",
                            },
                            {
                                nameElm: "GlobalSettings",
                                onClick: () => setSidebar("globalSettings"),
                                buttonClass:
                                    "w-full flex items-center rounded-lg focus:outline-none hover:text-blue-400 hover:bg-gray-100 px-4 py-2 animate whitespace-no-wrap text-sm",
                            }
                        ]}
                    >
                        <span className="ml-3">Service Analytics</span>
                    </Listelement>
                </ul>
            </div>
        </aside>
    );
}
