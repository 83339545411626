import React from "react";
import axios from "axios";
import getHeaders from "../../helpers/frontend-admin";

const MODULES = [
    "em-theme-temp",
    "em-cmp-lib-prices",
    "em-cmp-lib-airmodules",
    "em-cmp-lib-bookings-flights",
    "em-cmp-lib-airmodules--bookings",
    "em-cmp-lib-airmodules--prices",
    "em-cmp-lib-tracking",
    "em-cmp-loader",
    "em-cmp-lib-airmodules-components"
];

// dev: https://em-frontend-admin-dev.airtrfx.com/registryInfoMapping
// prod: https://em-frontend-admin.airtrfx.com/registryInfoMapping

const URL_DEV = "https://em-frontend-admin-dev.airtrfx.com/registryInfoMapping";
const URL_PROD = "https://em-frontend-admin.airtrfx.com/registryInfoMapping";

export default function useRegistry() {
    const [dev, setDev] = React.useState([]);

    const [prod, setProd] = React.useState([]);

    const [loading, setLoading] = React.useState(true);

    const [error, setError] = React.useState("");

    async function getRegistry() {
        try {
            const { data: registryProd } = await axios.get(URL_PROD, {
                headers: getHeaders()
            });

            const { data: registryDev } = await axios.get(URL_DEV, {
                headers: getHeaders()
            });

            const { list: listProd } = await registryProd?.modules;

            const { list: listDev } = await registryDev?.modules;

            const formattedListProd = listProd.filter(({ name }) =>
                MODULES.some((M) => M === name)
            );

            const formattedListDev = listDev.filter(({ name }) =>
                MODULES.some((M) => M === name)
            );

            setProd(formattedListProd);

            setDev(formattedListDev);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getRegistry();
        // eslint-disable-next-line
    }, []);

    return {
        dev,
        prod,
        loading,
        error,
    };
}
