import React from "react";
import { RenderIf } from "components";
import { useModuleContext } from "context/moduleContext";
import TRANSLATE_MODULE from "helpers/modulesTranslator";
import { useRegistryContext } from "context/registryContext";

export default function Intro({ toggle, setToggle }) {
    const { module, currentVersion } = useModuleContext();

    const { env } = useRegistryContext();

    return (
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div>
                <h2 className="text-left text-blue-800 font-bold text-2xl uppercase mt-3 flex items-center leading-none">
                    {TRANSLATE_MODULE[module.name]}{" "}
                    <span className="ml-2 text-blue-400 font-normal">
                        ({env}{" "}
                        <RenderIf isTrue={module.name !== "em-theme-temp"}>
                            <span className="text-blue-400 font-normal">
                                / {currentVersion}
                            </span>
                        </RenderIf>
                        )
                    </span>
                </h2>
                <div className="text-gray-700 mt-2">
                    <p>
                        Latest version:{" "}
                        <strong>{module.versions.meta.major}</strong> | Versions
                        count: <strong>{module.versions.meta.count}</strong>{" "}
                        <RenderIf isTrue={module.name !== "em-theme-temp"}>
                            | Components:{" "}
                            <strong>
                                {
                                    module.versions.map[currentVersion]
                                        .components.list.length
                                }
                            </strong>
                        </RenderIf>
                    </p>
                </div>
            </div>
            <RenderIf isTrue={module.name !== "em-theme-temp"}>
                <RenderIf isTrue={!toggle}>
                    <button
                        onClick={() => setToggle(true)}
                        className="text-blue-800 h-10 px-5 border border-blue-800 rounded focus:outline-none whitespace-no-wrap mt-3 lg:mt-0"
                    >
                        Show versions
                    </button>
                </RenderIf>
                <RenderIf isTrue={toggle}>
                    <button
                        onClick={() => setToggle(false)}
                        className="text-blue-800 h-10 px-5 border border-blue-800 rounded focus:outline-none mt-3 lg:mt-0"
                    >
                        Show components
                    </button>
                </RenderIf>
            </RenderIf>
        </div>
    );
}
