import React from "react";
import { useGoogleLogin, useGoogleLogout } from "react-google-login";

const clientId =
  process.env.REACT_APP_CLIENT_ID ||
  "410272187653-1jq6j978df2n8muo8ojdtibh1mvaagi3.apps.googleusercontent.com";

let GoogleAuthContext;
const { Provider } = (GoogleAuthContext = React.createContext());
// use context as a hook
export const useGoogleAuthContext = () => React.useContext(GoogleAuthContext);

export default function GoogleAuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = React.useState(false);

  const refreshTokenSetup = (res) => {
    // Timing to renew access token
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      // saveUserToken(newAuthRes.access_token);  <-- save new token
      localStorage.setItem("authToken", newAuthRes.id_token);

      // Setup the other timer after the first one
      setTimeout(refreshToken, refreshTiming);
    };

    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming);
  };

  const resLogin = useGoogleLogin({
    onSuccess: (res) => {
      console.log("Success Login");
      refreshTokenSetup(res);
      if (
        res.profileObj.email.endsWith("@everymundo.com") ||
        res.profileObj.email === "a11yweb.solutions@gmail.com" ||
        res.profileObj.email === "avivero@double.us" ||
        res.profileObj.email === "devs@double2.us"
      ) {
        setCurrentUser(res.profileObj);
      } else {
        setCurrentUser(false);
      }
    },
    onFailure: (res) => {
      console.log("Error Login", res);
      setCurrentUser(false);
    },
    clientId,
    isSignedIn: true,
    accessType: "offline",
  });

  const resLogout = useGoogleLogout({
    clientId,
    onLogoutSuccess: (res) => {
      console.log("Success Logout");
      setCurrentUser(false);
    },
    onFailure: () => {
      console.log("Error Logout");
    },
  });

  React.useEffect(() => {
    resLogin.signIn();
  }, []);
  
  return (
    <Provider
      value={{
        currentUser,
        resLogin,
        resLogout
      }}
    >
      {children}
    </Provider>
  );
}
