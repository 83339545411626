import React from "react";
import everymundoPros from "./index.jpeg"

export default function Logo() {
    return (
        <span className="block mt-5 mb-4 text-2xl font-bold leading-none text-blue-400 uppercase md:text-left">
            <img src={everymundoPros} alt="Logo" />
            <span className="-mt-2 text-sm text-blue-800 normal-case">
                Restart & Re-Acquire
            </span>
        </span>
    );
}
