import React from "react";
import img from "image.svg";
import { FaStar as Check } from "react-icons/fa";
import { MdAirplanemodeActive as Plane } from "react-icons/md";
import { GoServer as Server } from "react-icons/go";
import { IoMdSpeedometer as Fast } from "react-icons/io";
import { TopBarHome } from "components";
import { useTenantsConfig } from "hooks"

function Stat({ value, text }) {
    return (
        <div>
            <p className="text-5xl font-bold leading-tight text-blue-800">
                {value}
            </p>
            <p>{text}</p>
        </div>
    );
}

export default function Home() {
    const { tenantsConfig, loading } = useTenantsConfig();
    if(!loading) console.log("TC: ", tenantsConfig);

    return (
        <>
            <TopBarHome/>
            <div className="p-6">
                <div className="flex flex-col text-lg leading-relaxed text-gray-700 lg:items-center lg:flex-row">
                    <div className="w-64 lg:w-5/12">
                        <img src={img} alt="" />
                    </div>
                    <div className="flex-1 mt-10 lg:mt-0 lg:ml-10">
                        <p className="mt-2 mb-2 text-2xl font-bold text-blue-400">Welcome!</p>
                        <p className="mb-3 text-2xl font-black leading-none text-blue-800">
                            Everymundo Airmodules Dashboard
                        </p>
                        <p className="leading-relaxed">
                            Thanks for checking Everymundo new Frontend Dashboard,
                            click on the top bar items to access airmodules
                            components and versions, access to tenants steps/context
                            on the left sidebar.
                        </p>
                        <p className="my-3 font-bold text-blue-800">What's new?</p>
                        <ul className="space-y-3 text-sm font-bold text-blue-800">
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                Fresh UI
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />2
                                environments SPA (PROD / DEV), no more 2 URLs
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                One-click switch from PROD to DEV and vise versa
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                Access to Themes versions!!!
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                One-click include/remove react 16 in your SSR URL
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                One-click turn SSR URL ready for local development
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                One-click change styles URL
                                (DEV/PROD/PRICES/BOOKING)
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                No need to render the em-dashboard component locally
                                anymore!!!!
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                No need to build prices locally for client theme
                                implementation!!!!
                            </li>
                            <li className="flex items-center">
                                <Check className="mr-2 text-blue-400" />
                                Mobile ready!
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col justify-between mt-12 -mx-3 text-gray-700 sm:flex-row">
                    <div className="flex-1 p-4 m-3 bg-white rounded-lg shadow">
                        <Plane className="text-2xl text-blue-400" />
                        <Stat value="54" text="Active Airlines" />
                    </div>
                    <div className="flex-1 p-4 m-3 bg-white rounded-lg shadow">
                        <Server className="text-2xl text-blue-400" />
                        <Stat value="21M" text="Airline Pages Hosted" />
                    </div>
                    <div className="flex-1 p-4 m-3 bg-white rounded-lg shadow">
                        <Fast className="text-2xl text-blue-400" />
                        <Stat value="800" text="Pages Served Per Second" />
                    </div>
                </div>
            </div>
        </>
    );
}
