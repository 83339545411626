import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector } from "recharts";
import { formatNumber } from "../../helpers";

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
    } = props;

    const arrayList = Object.keys(payload.payload);
    let value = "";
    let name = "";
    arrayList.forEach((valKey) => {
        if (valKey === "count") {
            value = payload[valKey];
        } else {
            name = payload[valKey];
        }
    });
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill={fill}
            >{`${formatNumber(value)}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

export default function PieChartCmp(props) {
    const {
        dataList = [],
        dataKey = "value",
        fillPie,
        chartTitle,
        chartTitleClassName,
    } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    return (
        <div className="flex flex-col w-full justify-center bg-gray-100 shadow-md rounded-lg p-6 space-y-4">
            <div className={chartTitleClassName}>{chartTitle}</div>
            <div className="w-full flex justify-center items-center">
                <PieChart width={400} height={250}>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={dataList}
                        cx={200}
                        cy={100}
                        innerRadius={40}
                        outerRadius={60}
                        fill={fillPie}
                        dataKey={dataKey}
                        onMouseEnter={onPieEnter}
                    />
                </PieChart>
            </div>
        </div>
    );
}
