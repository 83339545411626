import React from "react";
import axios from "axios";
import getHeaders from "../../helpers/frontend-admin";

const URL = "https://em-frontend-admin.airtrfx.com/tenantsConfig";

export default function useTenantsConfig() {
    const [tenantsConfig, setTenantsConfig] = React.useState([]);

    const [loading, setLoading] = React.useState(true);

    const [error, setError] = React.useState("");

    async function getTenantsConfig() {
        try {
            const { data } = await axios.get(URL, {
                headers: getHeaders()
            });
        
            const groupByDefault = {};
            const mainDefault = [];
            for (const tc of data) {
                groupByDefault[tc.componentName] = groupByDefault[tc.componentName] === undefined ? 0 : groupByDefault[tc.componentName] + 1;
                if ((tc.context || {}).airline === "default") mainDefault.push(tc.componentName);
            }
            for (const key of mainDefault) {
                delete groupByDefault[key]
            }
            
            
            setTenantsConfig({groupByDefault, mainDefault} );
        } catch (error) {
            setError("Error loading tenants");
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getTenantsConfig();
    }, []);

    return { tenantsConfig, loading, error };
}
