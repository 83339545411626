export default {
    "em-theme-temp": "theme",
    "em-cmp-lib-prices": "prices",
    "em-cmp-lib-airmodules": "air modules",
    "em-cmp-lib-airmodules--bookings": "booking",
    "em-cmp-lib-bookings-flights": "booking flights",
    "em-cmp-lib-airmodules--prices": "new prices",
    "em-cmp-lib-tracking": "tracking",
    "em-cmp-loader": "loader",
    "em-cmp-lib-airmodules-components": "AirModules 3",
};
