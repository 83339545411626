import React from "react";
import { useRegistry } from "hooks";

let RegistryContext;
const { Provider } = (RegistryContext = React.createContext());
// use context as a hook
export const useRegistryContext = () => React.useContext(RegistryContext);

export default function RegistryContextProvider({ children }) {
    const { dev, prod, loading, error } = useRegistry();

    const [env, setEnv] = React.useState("dev");

    return (
        <Provider
            value={{
                registry: env === "dev" ? dev : prod,
                dev,
                prod,
                loading,
                error,
                env,
                setEnv,
            }}
        >
            {children}
        </Provider>
    );
}
