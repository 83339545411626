import React from "react";
import { useGoogleAuthContext } from "context/googleAuthContext";

export default function Unauthenticated() {
  const { resLogin } = useGoogleAuthContext();

  return (
    <>
      <div className="fixed h-screen w-screen flex items-center justify-center">
        <div className="absolute inset-0 bg-gray-200 z-10"></div>

        <div className="max-w-xl w-full bg-white shadow-lg z-50 rounded-lg overflow-hidden">
          <div className="p-4 flex space-x-4 md:flex-row flex-col md:text-left text-center items-center">
            <div className="bg-red-50 p-3 md:self-start rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 fill-current text-red-700"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" />
              </svg>
            </div>
            <div>
              <h1 className="text-xl font-semibold tracking-wide text-red-700">
                User not allowed
              </h1>
              <p className="text-gray-500">
                You need to register with an everymundo account to be able to
                access.
              </p>
            </div>
          </div>

          <div className="p-3 bg-gray-50 text-right md:space-x-4 md:block flex flex-col-reverse">
            <button
              onClick={resLogin.signIn}
              className="mb-2 md:mb-0 px-4 md:py-1.5 py-2 bg-red-700 text-white rounded-lg focus:ring-offset-2 focus:outline-none focus:ring-2 focus:ring-red-800 hover:bg-red-800"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
