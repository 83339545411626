export default function (version, moduleName, componentName, env, style) {
    return `(function()
    { 
    var iDiv = document.createElement('div');
    iDiv.setAttribute('data-em-module-id', '${moduleName}');
    iDiv.setAttribute('data-em-version', '${version}');
    iDiv.setAttribute('data-em-cmp', '${componentName}');
    iDiv.setAttribute('data-em-model-source-type', 'ajax');
    iDiv.setAttribute('data-em-styles', '${style}');
    iDiv.setAttribute('data-em-core-version', 'atlantis');
    iDiv.setAttribute('data-em-static-assets-host', '${
        env === "dev"
            ? "https://em-frontend-assets-dev.airtrfx.com"
            : "https://em-frontend-assets.airtrfx.com"
    }');
    iDiv.setAttribute('data-em-type', 'react-component');
    iDiv.setAttribute('data-em-cmp-id', 'fciED');
    iDiv.setAttribute('data-em-registry-host', '${
        env === "dev"
            ? "https://em-frontend-get-dev.airtrfx.com"
            : "https://em-frontend-get.airtrfx.com"
    }');
    document.getElementsByTagName('body')[0].appendChild(iDiv);        
})();

var staticAssets =  [
"https://em-frontend-assets.airtrfx.com/components/em-cmp-loader/2.2.13/components/em-cmp-loader/loader.bundle.js",
"https://em-frontend-assets.airtrfx.com/components/em-cmp-client-utils/1.6.2/components/em-cmp-vendors/vendors.bundle.min.js"];

for(var i = 0; i < staticAssets.length; i++){
    (function() {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = staticAssets[i];
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    })();
}
`;
}
