import React from "react";
import { MdCode, MdArrowForward } from "react-icons/md";
import { RenderIf } from "components";

export default function listComponentItem({
    name,
    env,
    currentVersion,
    selectedAirlineContext,
    selectedTenant,
}) {
    return (
        <span className="flex flex-col lg:flex-row lg:items-center">
            <span className="flex items-center">
                <MdCode className="mr-2" /> {name}{" "}
            </span>
            <span className="flex items-center">
                <MdArrowForward className="lg:mx-2" />
                <span className="uppercase text-blue-400 font-normal">
                    ({env} / {currentVersion})
                </span>
            </span>
            <span className="flex items-center">
                <MdArrowForward className="lg:mx-2" />
                <span className="uppercase">{selectedTenant.code}</span>
                <RenderIf isTrue={selectedAirlineContext?.datasource?.step}>
                    <MdArrowForward className="mx-2" />
                    <span className="uppercase font-normal text-blue-400">
                        {selectedAirlineContext.datasource?.step}
                    </span>
                </RenderIf>
            </span>
        </span>
    );
}
