

export const servicesFetchData = {
    cloudflare: {
        url: "https://em-frontend-admin-dev.airtrfx.com/dashboard",
        header: {
            "Content-Type": "application/json",
            "x-api-key": "ipfOD~HysFRVKZSigUYJ"
        }
    }

}