import React from "react";
import { RenderIf } from "components";
import TenantsBarItem from "./tenantsBarItem";
import { MdAirplanemodeActive } from "react-icons/md";
import { useTenantContext } from "context/tenantsContext";

import Placeholder from "./placeholder";

export default function Tenantsbar() {
    const {
        tenants,
        loading,
        error,
        selectedTenant,
        handleSelectTenant,
    } = useTenantContext();

    const [search, setSearch] = React.useState("");

    function handleSearch(e) {
        const { value } = e.target;
        setSearch(value);
    }

    function tenantsFiltered(list) {
        return list.filter(
            ({ name, code }) =>
                name.toUpperCase().includes(search.toUpperCase()) ||
                code.toUpperCase().includes(search.toUpperCase())
        );
    }

    if (error) return <p>{error}</p>;

    return (
        <div
            className="fixed top-0 right-0 z-30 h-screen px-5 mt-20 mb-20 overflow-y-auto text-left bg-white shadow"
            style={{ width: 300, minWidth: 300 }}
        >
            <button
                className="visible float-right mt-3 text-3xl leading-none text-blue-800 lg:hidden"
            >
                &times;
            </button>
            <RenderIf isTrue={loading}>
                <Placeholder />
            </RenderIf>
            <RenderIf isTrue={tenants.length}>
                <div className="fixed pr-10 bg-white">
                    <input
                        type="search"
                        placeholder="Search Tenant"
                        className="w-full h-12 p-5 my-4 text-lg text-blue-800 placeholder-blue-800 bg-gray-200 border rounded-lg focus:outline-none animate focus:shadow-xl"
                        onChange={handleSearch}
                    />
                </div>
                <nav className="flex flex-col mt-20 mb-20 list-none">
                    {tenantsFiltered(tenants).map(({ name, code, hasFc }) => (
                        <TenantsBarItem
                            key={code}
                            code={code}
                            isActive={code === selectedTenant?.code}
                            onClick={() => handleSelectTenant(code)}
                            contexts={selectedTenant?.context}
                        >
                            <MdAirplanemodeActive
                                className={`mr-2 ${
                                    !hasFc ? "text-red-400" : ""
                                }`}
                            />
                            {name}
                        </TenantsBarItem>
                    ))}
                </nav>
            </RenderIf>
        </div>
    );
}
