import React from "react";
import { useRegistryContext } from "context/registryContext";
import { useModuleContext } from "context/moduleContext";

const buttonActive =
    "h-8 flex-1 bg-blue-800 text-blue-100 focus:outline-none whitespace-no-wrap";

const button = "h-8 flex-1 text-blue-800 focus:outline-none whitespace-no-wrap";

export default function Switcher() {
    const { setEnv, env, dev, prod } = useRegistryContext();

    const { handleSetModule, module } = useModuleContext();

    function handleClick(e) {
        const { value } = e.target;
        const registry = value === "dev" ? dev : prod;
        setEnv(value);
        if (module) {
            const m = registry.find((r) => r.name === module.name);
            handleSetModule(m);
        }
    }

    const devStyle = env === "dev" ? buttonActive : button;

    const prodStyle = env === "prod" ? buttonActive : button;

    return (
        <div
            className="block overflow-hidden flex h-8 rounded border border-blue-800 mt-3 lg:mt-0"
            style={{ width: 160 }}
        >
            <button className={devStyle} value="dev" onClick={handleClick}>
                Dev
            </button>
            <button className={prodStyle} value="prod" onClick={handleClick}>
                Prod
            </button>
        </div>
    );
}
