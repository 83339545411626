import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import TenantContextProvider from "context/tenantsContext";
import ModuleContextProvider from "context/moduleContext";
import RegistryContextProvider from "context/registryContext";
import GoogleAuthContextProvider from "context/googleAuthContext";

ReactDOM.render(
  <React.StrictMode>
    <GoogleAuthContextProvider>
      <RegistryContextProvider>
        <TenantContextProvider>
          <ModuleContextProvider>
            <App />
          </ModuleContextProvider>
        </TenantContextProvider>
      </RegistryContextProvider>
    </GoogleAuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
