import React from "react";

const Analytics = () => {
    return (
        <div className="flex text-2xl justify-center items-center w-full h-screen font-black leading-none text-blue-800">
            Here will go a general description of all the services listed under
            Analytics section
        </div>
    );
};

export default Analytics;
