import React from "react";
import { useRegistryContext } from "context/registryContext";

let ModuleContext;
const { Provider } = (ModuleContext = React.createContext());
// use context as a hook
export const useModuleContext = () => React.useContext(ModuleContext);

export default function ModuleContextProvider({ children }) {
    const { dev } = useRegistryContext();

    const [currentVersion, setCurrentVersion] = React.useState("");

    const [module, setModule] = React.useState(null);

    function handleSetModule(moduleToSet) {
        setModule(moduleToSet);
        setCurrentVersion(moduleToSet.versions.meta.major);
    }

    const initialModule = dev.find((c) => c.name === "em-theme-temp");

    React.useEffect(() => {
        setModule(initialModule);
        if (initialModule) {
            setCurrentVersion(initialModule.versions.meta.major);
        }
        // eslint-disable-next-line
    }, [dev]);

    return (
        <Provider
            value={{
                module,
                handleSetModule,
                currentVersion,
                setCurrentVersion,
                setModule,
            }}
        >
            {children}
        </Provider>
    );
}
