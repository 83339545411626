import React from "react";
import { VersionItem, RenderIf, ModuleItem, Preloader } from "components";
import { mapVersions } from "helpers";
import Intro from "./intro";
import { useModuleContext } from "context/moduleContext";
import { useRegistryContext } from "context/registryContext";
import { MdCode } from "react-icons/md";
import { GiPaintRoller } from "react-icons/gi";
import { useTenantContext } from "context/tenantsContext";
import ModuleListItemName from "./moduleListItemName";
import Placeholder from "./placeholder";

export default function Content() {
    const { loading, env } = useRegistryContext();
    const { selectedTenant, selectedAirlineContext } = useTenantContext();
    const [toggle, setToggle] = React.useState(false);
    const { module, currentVersion } = useModuleContext();
    const [search, setSearch] = React.useState("");
    console.log(module, currentVersion)

    if (loading)
        return (
            <div className="container relative h-64 mx-auto text-center">
                <Preloader text="Everymundo's cool stuff loading..." />
            </div>
        );

    if (!module) return <Placeholder />;

    function handleSearch(e) {
        const { value } = e.target;
        setSearch(value);
    }

    const moduleVersions = mapVersions(module.versions.map);

    const { list: moduleComponents } = module.versions.map[
        currentVersion
    ].components;

    function componentsFiltered() {
        return moduleComponents.filter(({ name }) =>
            name.toUpperCase().includes(search.toUpperCase())
        );
    }

    return (
        <div className="relative m-6 airCmpContainer" style={{
            width: `calc(100% - 346px)`
            }}>
            <Intro setToggle={setToggle} toggle={toggle}
        />
            <RenderIf isTrue={module.name === "em-theme-temp"}>
                <div className="flex flex-wrap mt-5 -mx-2">
                    {moduleVersions.map((v, i) => (
                        <VersionItem key={`version-${i}`} version={v}>
                            <span className="flex items-center justify-center">
                                <GiPaintRoller className="mr-2" />
                                {v}
                                <span className="ml-1 font-normal text-blue-400 uppercase">
                                    ({env})
                                </span>
                            </span>
                        </VersionItem>
                    ))}
                </div>
            </RenderIf>
            <RenderIf isTrue={module.name !== "em-theme-temp"}>
                <RenderIf isTrue={!toggle}>
                    <input
                        type="search"
                        placeholder="Search components by name"
                        className="sticky top-0 w-full h-16 px-8 my-5 text-xl text-blue-800 placeholder-blue-800 bg-gray-200 border rounded-lg focus:outline-none focus:shadow-2xl animate"
                        onChange={handleSearch}
                        value={search}
                    />

                    {componentsFiltered()
                        .filter(({ name }) => name !== "em-cmp-dashboard")
                        .map(({ name }, i) => (
                            <ModuleItem
                                name={
                                    <ModuleListItemName
                                        name={name.replace("em-cmp-", "")}
                                        env={env}
                                        currentVersion={currentVersion}
                                        selectedAirlineContext={
                                            selectedAirlineContext
                                        }
                                        selectedTenant={selectedTenant}
                                    />
                                }
                                key={`module-map-${i}`}
                                componentName={name.replace("em-cmp-", "")}
                                moduleName={module.name}
                            />
                        ))}
                </RenderIf>
                <RenderIf isTrue={toggle}>
                    <div className="flex flex-wrap mt-5 -mx-2">
                        {moduleVersions.map((v, i) => (
                            <VersionItem
                                asButton
                                key={`version-${i}`}
                                version={v}
                                setToggle={setToggle}
                            >
                                <span className="flex items-center justify-center">
                                    <MdCode className="mr-2" />
                                    {v}
                                    <span className="ml-1 font-normal text-blue-400 uppercase">
                                        ({env})
                                    </span>
                                </span>
                            </VersionItem>
                        ))}
                    </div>
                </RenderIf>
            </RenderIf>
        </div>
    );
}
