import { useEffect, useState } from "react";
import axios from "axios";
// import json_data from '../../json_samples/labels.json'
import processData from "helpers/processData";


const useService = ({fetchBody, fetchUrl, fetchHeader}) => {
    const {datetimeStart, datetimeEnd, clientRequestPath} = fetchBody
    const [response, setResponse] = useState({
        service: null,
        loading: true,
        error: null
    });

    const fetchData = async (fetchBody) => {
        setResponse({service:null, loading:true, error:null})
        try {
            const { data } = await axios.post(
                fetchUrl,
                {
                   ...fetchBody
                },
                {
                    timeout: 8000,
                    headers: fetchHeader
                }
            );
            
            const processDataObj = processData(data)
            setResponse({
                service: {...processDataObj},
                loading: false,
                error: null
            }); 
        } catch (error) {
            console.log('ERROR: ',error)
            if (error.code === 'ECONNABORTED')
                setResponse({
                    service: null,
                    loading: false,
                    error: 'Looks like the server is taking to long to respond...'
                })
            else
                setResponse({
                    service: null,
                    loading: false,
                    error: error.message
                })
        }
       
    };

    useEffect(() => {
        if(datetimeStart && datetimeEnd && clientRequestPath) 
            fetchData({...fetchBody});
    }, [datetimeStart, datetimeEnd, clientRequestPath]);

    return {...response};
};

export default useService