import React from "react";
import { FiLogOut } from "react-icons/fi";
import { useGoogleAuthContext } from "context/googleAuthContext";

export default function ProfileMenu() {
  const { currentUser, resLogout } = useGoogleAuthContext();

  return (
    <div className="relative inline-block text-left dropdown">
      <button
        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium 
        leading-5 text-gray-700 transition duration-150 ease-in-out bg-transparent rounded-md hover:text-gray-500 
        focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
        type="button"
        aria-haspopup="true"
        aria-expanded="true"
        aria-controls="headlessui-menu-items-117"
      >
        <img
          className="block mx-auto h-8 rounded-full sm:mx-0 sm:flex-shrink-0"
          src={(currentUser|| {}).imageUrl}
          alt="profile"
        ></img>
      </button>
      <div className="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
        <div
          className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
          aria-labelledby="headlessui-menu-button-1"
          id="headlessui-menu-items-117"
          role="menu"
        >
          <div className="px-4 py-3">
            <p className="text-sm leading-5">Signed in as:</p>
            <p className="text-sm font-medium leading-5 text-gray-900 truncate">
              {(currentUser || {}).email}
            </p>
          </div>
          <div className="py-1">
            <button
              tabIndex="0"
              className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-800 hover:text-white"
              role="menuitem"
            >
              Account settings
            </button>
            <button
              tabIndex="1"
              className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-800 hover:text-white"
              role="menuitem"
            >
              Support
            </button>
            <button
              tabIndex="2"
              className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-800 hover:text-white"
              role="menuitem"
            >
              License
            </button>
          </div>
          <a
            href="/"
            onClick={resLogout.signOut}
            className="w-full text-left inline-flex px-4 py-2 text-sm capitalize text-gray-700 
            hover:bg-blue-800 hover:text-white"
          >
            <FiLogOut/>
            Sign Out
          </a>
        </div>
      </div>
    </div>
  );
}
