import React from "react";

export default function ContentPlaceholder() {
    const a = new Array(20).fill("");
    return (
        <div className="container mx-auto relative">
            <div className="h-12 bg-gray-300"></div>
            <ul className="relative items-center text-blue-800 space-y-5">
                {a.map((i, index) => (
                    <li key={index} className="h-16 w-full bg-gray-300"></li>
                ))}
            </ul>
        </div>
    );
}
