import React from "react";
import { Content, TenantsBar, TopBar, RenderIf } from "components";
import { useModuleContext } from "context/moduleContext";

export default function AirComponents() {
    const { module } = useModuleContext();

    return (
        <>
            <TopBar/>
            <TenantsBar/>
            <RenderIf isTrue={module}>
                <Content />
            </RenderIf>
        </>
    );
}
