import React from "react";
import { RenderIf } from "components";
import TRANSLATE_MODULE from "helpers/modulesTranslator";
import Switcher from "components/topBar/switcher";
import ListItem from "./listItem";
import { useRegistryContext } from "context/registryContext";
import { IoMdMenu } from "react-icons/io";
import Placeholder from "./placeholder";
import { ProfileMenu } from "../auth";

export default function TopBar() {
    const { registry, loading } = useRegistryContext();

    return (
        <>
            <div className="relative z-10 flex flex-col justify-between w-full px-6 py-4 bg-gray-100 border-b lg:flex-row lg:sticky lg:top-0 lg:items-center">
                <button
                    className="w-12 mb-3 text-3xl text-blue-800 lg:hidden focus:outline-none"
                >
                    <IoMdMenu />
                </button>
                <RenderIf isTrue={loading}>
                    <Placeholder />
                </RenderIf>
                <RenderIf isTrue={!loading}>
                    <div className="flex-1 w-full pr-3 mr-4 overflow-x-auto border-r">
                        <ul className="relative flex items-center flex-1 text-blue-800 whitespace-no-wrap">
                            {registry.map(({ name }, i) => (
                                <li key={i} className="mr-4">
                                    <ListItem
                                        label={TRANSLATE_MODULE[name]}
                                        name={name}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Switcher />
                </RenderIf>
                <ProfileMenu />
            </div>
        </>
    );
}
