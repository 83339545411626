

export default function processData(data) {
    const countries = data && data["viewer"] && data["viewer"]["zones"] && data["viewer"]["zones"][0]["countries"].map(
        (c) => ({
            country: c?.dimensions?.metric || '',
            count: c?.count || 0,
        })
    );

    const cacheStatuses = data && data["viewer"] && data["viewer"]["zones"] && data["viewer"]["zones"][0][
        "topCacheStatuses"
    ].map((c) => ({
        status: c?.dimensions?.metric || '',
        count: c?.count || 0,
    }));
    const total = data && data["viewer"] && data["viewer"]["zones"] && data["viewer"]["zones"][0]["total"][0]?.count;

    const dataCenters = data && data["viewer"] && data["viewer"]["zones"] && data["viewer"]["zones"][0]["topColoCodes"].map(
        (c) => ({
            status: c.dimensions.metric,
            count: c.count,
        })
    );

    const edgeStatusCodes = data && data["viewer"] && data["viewer"]["zones"] && data["viewer"]["zones"][0][
        "topEdgeStatusCodes"
    ].map((c) => ({
        status: c.dimensions.metric,
        count: c.count,
    }));

    return {countries, cacheStatuses, total, dataCenters, edgeStatusCodes}
}