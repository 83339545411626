import React from "react";
import axios from "axios";
import getHeaders from "../../helpers/frontend-admin";
import { sortBy } from "../../helpers";

const URL = "https://em-frontend-admin-dev.airtrfx.com/tenants";

const CONTEXT_URL = "https://em-frontend-admin-dev.airtrfx.com/staticContexts";

export default function useTenants() {
    const [tenants, setTenants] = React.useState([]);

    const [loading, setLoading] = React.useState(true);

    const [error, setError] = React.useState("");

    async function getTenants() {
        try {
            const { data } = await axios.get(URL, {
                headers: getHeaders()
            });
            const { data: contextData } = await axios.get(CONTEXT_URL, {
                headers: getHeaders()
            });
            const newData = data.map((t) => {
                const contexts = contextData.find(
                    (c) => c.tenantCode === t.code
                );
                return contexts
                    ? { ...t, context: contexts.contexts }
                    : { ...t };
            });
            const orderedData = newData.concat().sort(sortBy("name"));
            setTenants(orderedData);
        } catch (error) {
            setError("Error loading tenants");
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getTenants();
    }, []);

    return { tenants, loading, error };
}
