import React from "react";
import { RenderIf } from "components";
import { useTenantContext } from "context/tenantsContext";

export default function TenantsBarItem({
    children,
    isActive = false,
    onClick = () => {},
    contexts = [],
}) {
    const { handleSelectContext, selectedAirlineContext } = useTenantContext();

    const isDisabled = (step) =>
        selectedAirlineContext.datasource.step === step;
    return (
        <>
            <button
                onClick={onClick}
                className={` font-bold py-2 hover:text-blue-400 cursor-pointer animate flex items-center focus:outline-none ${
                    isActive ? "underline text-blue-400" : "text-blue-800"
                }`}
            >
                {children}
            </button>
            <RenderIf isTrue={isActive && contexts.length > 0}>
                <ul className="mb-2">
                    {contexts.map(({ datasource: { step } }) => (
                        <li key={step} className="block w-full">
                            <button
                                disabled={isDisabled(step)}
                                className={`pl-12 h-8  capitalize hover:text-blue-400 ${
                                    isDisabled(step)
                                        ? "text-blue-400"
                                        : "text-blue-800"
                                }`}
                                onClick={() => handleSelectContext(step)}
                            >
                                {step}
                            </button>
                        </li>
                    ))}
                </ul>
            </RenderIf>
        </>
    );
}
