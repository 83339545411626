import React from "react";

export default function Placeholder() {
    const a = new Array(7).fill("");
    return (
        <>
            <ul className="relative flex items-center text-blue-800 space-x-6 blink">
                {a.map((i, index) => (
                    <li key={index} className="h-2 w-12 bg-gray-300"></li>
                ))}
            </ul>
            <div className="h-2 w-12 bg-gray-300 hidden lg:visible blink"></div>
        </>
    );
}
