import React from "react";
import { useTenants } from "hooks";

let TenantContext;
const { Provider } = (TenantContext = React.createContext());
// use context as a hook
export const useTenantContext = () => React.useContext(TenantContext);

export default function TenantContextProvider({ children }) {
    const { tenants, loading, error } = useTenants();

    const [selectedTenant, setSelectecTenant] = React.useState(null);

    const [selectedAirlineContext, setSelectedAirlineContext] = React.useState(
        null
    );

    function handleSelectTenant(code) {
        const toTenant = tenants.find((t) => t.code === code);
        setSelectecTenant(toTenant);
        setSelectedAirlineContext(toTenant.context ? toTenant.context[0] : {});
    }

    function handleSelectContext(step) {
        const toContext = selectedTenant.context.find(
            (c) => c.datasource.step === step
        );
        setSelectedAirlineContext(toContext);
    }

    React.useEffect(() => {
        const INITIAL_SELECTED_TENANT = tenants.find(
            (t) => t.code.toUpperCase() === "TX"
        );
        const INITIAL_CONTEXT = INITIAL_SELECTED_TENANT?.context[0];

        setSelectecTenant(INITIAL_SELECTED_TENANT);

        setSelectedAirlineContext(INITIAL_CONTEXT);
    }, [tenants]);

    return (
        <Provider
            value={{
                tenants,
                loading,
                error,
                selectedTenant,
                handleSelectTenant,
                selectedAirlineContext,
                handleSelectContext,
            }}
        >
            {children}
        </Provider>
    );
}
