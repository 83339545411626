import React from "react";
import { IoMdMenu } from "react-icons/io";
import { ProfileMenu } from "../auth";
import Switcher from "components/topBar/switcher";

export default function TopBarHome() {

    return (
        <>
            <div className="relative z-10 flex justify-end w-full px-6 py-4 bg-gray-100 border-b lg:flex-row lg:sticky lg:top-0 lg:items-center">
                <button
                    className="w-12 mb-3 text-3xl text-blue-800 lg:hidden focus:outline-none"
                >
                    <IoMdMenu />
                </button>
                <Switcher />
                <ProfileMenu />
            </div>
        </>
    );
}
