export const STYLES_URLS = (iata, version = "") => [
    {
        name: "Base EDS",
        value: `https://everymundo.github.io/em-theme-temp/themes/base_abstracts/index.base_abstract.css`,
    },
    {
        name: "Prices DEV",
        value: `https://everymundo.github.io/em-theme-temp/themes/${iata}/index.isolated.${iata}.css`,
    },
    {
        name: "Prices PROD",
        value: `https://em-frontend-assets.airtrfx.com/components/em-theme-temp/${version}/themes/${iata}/index.isolated.${iata}.css`,
    },
    {
        name: "Booking DEV",
        value: `https://everymundo.github.io/em-theme-temp/themes/booking/${iata}/index.isolated.${iata}.css`,
    },
    {
        name: "Booking PROD",
        value: `https://em-frontend-assets.airtrfx.com/components/em-theme-temp/${version}/themes/booking/${iata}/index.isolated.${iata}.css`,
    },
];
