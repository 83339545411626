import React from "react";

const styledButton =
    "text-sm px-4 h-8 whitesapce-nowrap mr-2 mb-2 text-blue-100 bg-blue-800 rounded focus:outline-none";

const styledButtonActive =
    "text-sm px-4 h-8 whitesapce-nowrap border border-blue-800 mr-2 mb-2 text-blue-800 bg-white rounded focus:outline-none";

export default function StylesHints({ list = [], setStyle, style, version }) {
    function handleClick(e) {
        setStyle(e.target.value);
    }
    return (
        <div className="text-sm font-bold text-blue-800">
            <span className="mr-3 block my-2">Styles hints:</span>
            {list.map(({ name, value }, index) => (
                <button
                    onClick={handleClick}
                    key={name}
                    className={
                        style === value ? styledButtonActive : styledButton
                    }
                    value={value}
                >
                    {name} {index === 2 || index === 4 ? version : ""}
                </button>
            ))}
        </div>
    );
}
