import React from "react";
import { RenderIf } from "components";
import { useModuleContext } from "context/moduleContext";

const address = (version) =>
    `https://em-frontend-assets.airtrfx.com/components/em-theme-temp/${version}/themes/br/index.isolated.br.css`;

const styledVersion =
    "block w-full shadow-lg rounded-lg text-center py-3 text-lg font-bold bg-white text-blue-800 focus:outline-none";

export default function VersionItem({
    children,
    asButton = false,
    version = "",
    setToggle = () => {},
}) {
    const { setCurrentVersion } = useModuleContext();

    function handleSetNewVersion() {
        setCurrentVersion(version);
        setToggle(false);
    }
    
    return (
        <div className="w-6/12 p-2 md:w-4/12 lg:w-3/12">
            <RenderIf isTrue={!asButton}>
                <a
                    href={address(version)}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styledVersion}
                >
                    {children}
                </a>
            </RenderIf>
            <RenderIf isTrue={asButton}>
                <button
                    target="_blank"
                    className={styledVersion}
                    onClick={handleSetNewVersion}
                >
                    {children}
                </button>
            </RenderIf>
        </div>
    );
}
