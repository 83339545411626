import React from "react";
import RenderIf from "../renderIf/renderIf";
import SSR from "components/codeMirror/ssr";
import CSR from "components/codeMirror/csr";
import { useModuleContext } from "context/moduleContext";
import { IoIosCode as Code } from "react-icons/io";
import { FiEye as Eye } from "react-icons/fi";
import EmIframe from "./iframe";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ShowRoomToggle({ state, handleShowRoom }) {
    const active = "bg-blue-800 text-blue-100";
    const pasive = "bg-white text-blue-800";

    const iframe = state ? active : pasive;

    const code = state ? pasive : active;

    return (
        <div className="inline-block float-right">
            <button
                className={`focus:outline-none border border-blue-800 text-sm h-8 px-4 rounded-l ${code}`}
                onClick={handleShowRoom}
            >
                <Code />
            </button>
            <button
                className={`focus:outline-none border border-blue-800 text-sm h-8 px-4 rounded-r ${iframe}`}
                onClick={handleShowRoom}
            >
                <Eye />
            </button>
        </div>
    );
}

export default function Module({
    name = "",
    componentName = "",
    moduleName = "",
}) {
    const { currentVersion } = useModuleContext();

    const [open, setOpen] = React.useState(false);

    const [showRoom, setShowRoom] = React.useState(false);

    const [iframeValue, setIframeValue] = React.useState({
        start: "",
        value: "",
    });

    function handleShowRoom() {
        setOpen(true);
        setShowRoom(!showRoom);
    }

    function handleClose() {
        setOpen(!open);
        setShowRoom(false);
    }

    const CopiedCode = (src) => ` 
    <iframe
        id={name}
        title={name}
        name={name}
        src="${src}"
        frameBorder="0"
        width="100%"
        height="900px"
    />`;

    return (
        <div className={`mb-6 shadow rounded-lg overflow-hidden`}>
            <button
                key={name}
                className={`w-full flex justify-between items-center px-8 py-6 focus:outline-none bg-white ${
                    !open ? "" : " border-b"
                } text-left text-lg font-bold text-blue-800 animate`}
                onClick={handleClose}
            >
                {name}
            </button>
            <RenderIf isTrue={open}>
                <div className="bg-white block p-8 w-full">
                    <ShowRoomToggle
                        state={showRoom}
                        handleShowRoom={handleShowRoom}
                    />
                    <RenderIf isTrue={showRoom}>
                        <CopyToClipboard
                            text={CopiedCode(
                                iframeValue.start +
                                    encodeURIComponent(iframeValue.value)
                            )}
                        >
                            <code
                                className="block relative text-red-400 text-xs px-5 pt-6 pb-3 border border-red-500 mt-12 rounded bg-red-100 cursor-pointer w-full"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                <span>
                                    {iframeValue.start + iframeValue.value}
                                </span>
                                <span className="text-xs text-white absolute top-0 left-0 px-2 bg-red-500">
                                    Click to copy
                                </span>
                            </code>
                        </CopyToClipboard>
                        <EmIframe
                            src={iframeValue.start + iframeValue.value}
                            name={componentName}
                        />
                    </RenderIf>
                    <RenderIf isTrue={!showRoom}>
                        <SSR
                            currentVersion={currentVersion}
                            componentName={componentName}
                            moduleName={moduleName}
                            setIframeValue={setIframeValue}
                        />
                        <CSR
                            currentVersion={currentVersion}
                            componentName={componentName}
                            moduleName={moduleName}
                        />
                    </RenderIf>
                </div>
            </RenderIf>
        </div>
    );
}
