import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

const Listelement = ({
    isExpanded = false,
    onClick,
    listExpandedElems = [],
    buttonClassName = "",
    children,
}) => {
    const [isExpand, setIsExpand] = useState(true);
    return (
        (!isExpanded && (
            <li className="p-2 text-base font-normal text-gray-900 align-middle rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <button className={buttonClassName} onClick={onClick}>
                    {children}
                </button>
            </li>
        )) || (
            <div className="">
                <li className="flex items-center w-full p-2 text-base font-normal text-gray-900 align-middle rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <button
                        className={buttonClassName}
                        onClick={() => setIsExpand(!isExpand)}
                    >
                        <FaChevronDown
                            style={{
                                transform: `${
                                    !isExpand
                                        ? "rotate(0deg)"
                                        : "rotate(180deg)"
                                }`,
                                transition:
                                    "transform .4s ease,-webkit-transform .4s ease",
                            }}
                        />
                        {children}
                    </button>
                </li>
                {isExpand && (
                    <ul className="flex flex-col">
                        {listExpandedElems.map(
                            ({ nameElm, onClick, buttonClass }, index) => (
                                <li key={index} className="pl-4">
                                    <button
                                        className={buttonClass}
                                        onClick={onClick}
                                    >
                                        {nameElm}
                                    </button>
                                </li>
                            )
                        )}
                    </ul>
                )}
            </div>
        )
    );
};

export default Listelement;
