import React, { useState, useEffect } from 'react';

const DebouncedSelect = ({ options, onSelect, delay = 500 }) => {
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    const timerId = setTimeout(() => {
      onSelect(selectedValue);
    }, delay)

    return () => {
      clearTimeout(timerId)
    };
  }, [selectedValue, onSelect, delay])

  const handleSelectChange = (e) => {
    const value = e.target.value
    setSelectedValue(value)
  }

  return (
    <div>
      <select id="standard-select" value={selectedValue} onChange={handleSelectChange} className="select-dropdown w-full h-full bg-transparent text-blue-gray-700 text-lg text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border rounded-md border-blue-800 py-3 px-2">
        {options.map((elem, index) => (
          <option key={index} value={elem.elemVal}>
              {elem.elemName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DebouncedSelect