import React from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/mode/jsx/jsx";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/lucario.css";
import csrString from "./csrString";
import "./style.css";
import { useRegistryContext } from "context/registryContext";
import Copy from "./copy";
import { STYLES_URLS } from "./stylesURLS";
import StylesHints from "./stylesHints";
import { useTenantContext } from "context/tenantsContext";

const options = {
    mode: "jsx",
    theme: "lucario",
    lineNumbers: true,
};

export default function SSR({
    currentVersion = "",
    componentName = "",
    moduleName = "",
}) {
    const { env, prod } = useRegistryContext();

    const { selectedTenant } = useTenantContext();

    const [value, setValue] = React.useState(null);

    const [copied, setCopied] = React.useState(false);

    const [style, setStyle] = React.useState(
        STYLES_URLS(selectedTenant.code)[0].value
    );

    React.useEffect(() => {
        const CODE_VALUE = csrString(
            currentVersion,
            moduleName,
            componentName,
            env,
            style
        );
        setValue(CODE_VALUE);
        // eslint-disable-next-line
    }, [env, componentName, style]);

    React.useEffect(() => {
        setStyle(STYLES_URLS(selectedTenant.code)[0].value);
    }, [selectedTenant]);

    const themeVersion = prod[prod.length - 1].versions.meta.major;

    if (!value || !style) return null;

    return (
        <>
            <div className="block relative mt-5">
                <Copy value={value} copied={copied} setCopied={setCopied} />
                <CodeMirror
                    value={value}
                    options={options}
                    autoCursor={false}
                    onChange={(editor, data, value) => {
                        setValue(value);
                        setCopied(false);
                    }}
                />
            </div>
            <StylesHints
                list={STYLES_URLS(selectedTenant.code, themeVersion)}
                setStyle={setStyle}
                style={style}
                version={themeVersion}
            />
        </>
    );
}
