import React from "react";
import { RenderIf } from "components";

export default function Preloader({ text = "" }) {
    return (
        <div className="absolute h-full w-full top-0 left-0 flex flex-col items-center justify-center">
            <div className="h-12 w-12 border-2 border-blue-200 rounded-full preloader" />
            <RenderIf isTrue={text}>
                <p className="text-lg block text-center text-blue-800 mt-5">
                    {text}
                </p>
            </RenderIf>
        </div>
    );
}
