import React from "react";

export default function Loading({containerClass, text="Please wait..."}) {
  return (
    <div className={containerClass}>
      <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
        <div className="loader-dots block relative w-20 h-5 mt-2">
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-blue-400"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-blue-400"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-blue-400"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-blue-400"></div>
        </div>
        <div className="text-blue-400 text-xs font-light mt-2 text-center">
          {text}
        </div>
      </div>
    </div>
  );
}