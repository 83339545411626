export default function (
    version,
    moduleName,
    componentName,
    env,
    localURL,
    react16,
    context,
    style
) {
    return !react16
        ? `
[{"moduleId":"${moduleName}",
  "fcTitle": "Component title",
  "type":"react-component",
  "name":"${componentName}",
  "version":"${version}",
  "wrap": "page--full-ssr",
  "ssrStyles": true,
  "styles": "${style}",
  "forDevelopmentTesting": true,
  "coreVersion": "atlantis",
  "staticAssetsHost": "${
      env === "dev"
          ? "https://em-frontend-assets-dev.airtrfx.com"
          : "https://em-frontend-assets.airtrfx.com"
  }",
  "context": ${JSON.stringify(context)}
}]
`
        : `
[{"moduleId":"${moduleName}",
  "fcTitle": "Component title",
  "type":"react-component",
  "name":"${componentName}",
  "version":"${version}",
  "wrap": "page--full-ssr",
  "ssrStyles": true,
  "styles": "${style}",
  "forDevelopmentTesting": true,
  "coreVersion": "atlantis",
  "staticAssetsHost": "${
      env === "dev"
          ? "https://em-frontend-assets-dev.airtrfx.com"
          : "https://em-frontend-assets.airtrfx.com"
  }",
  "reactVersion": "16",
  "reactDOMVersion": "16",
  "context": ${JSON.stringify(context)}
}]
`;
}
