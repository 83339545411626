import React from "react";
import Preloader from "components/preloader";

export default function EmIframe({ src, name }) {
    const [loading, setLoading] = React.useState(true);

    const ref = React.useRef(null);

    function handleOnLoad() {
        setLoading(false);
        ref.current.height = 900;
    }

    return (
        <div className="relative mt-10" style={{ minHeight: 300 }}>
            {loading && <Preloader />}
            <iframe
                id={name}
                title={name}
                name={name}
                src={src}
                ref={ref}
                frameBorder="0"
                width="100%"
                onLoad={handleOnLoad}
            />
        </div>
    );
}

// check sidebar click on context update
