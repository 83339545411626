import React from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/mode/jsx/jsx";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/lucario.css";
import ssrString from "./ssrString";
import "./style.css";
import { useRegistryContext } from "context/registryContext";
import { useTenantContext } from "context/tenantsContext";
import Copy from "./copy";
import StylesHints from "./stylesHints";
import { STYLES_URLS } from "./stylesURLS";

const styledButton =
    "h-8 rounded text-sm px-4 bg-blue-800 mb-2 text-blue-100 focus:outline-none mr-2";

const options = {
    mode: "jsx",
    theme: "lucario",
    lineNumbers: true,
};

export default function SSR({
    currentVersion = "",
    componentName = "",
    moduleName = "",
    setIframeValue,
}) {
    const { env, prod } = useRegistryContext();

    const {
        selectedAirlineContext: context,
        selectedTenant,
    } = useTenantContext();

    const [value, setValue] = React.useState(null);

    const [copied, setCopied] = React.useState(false);

    const [localURL, setLocalURL] = React.useState(false);

    const [react16, setReact16] = React.useState(false);

    const [style, setStyle] = React.useState("");

    // const URLStart = localURL
    //     ? "https://localhost:4443/ssr?modules="
    //     : env === "dev"
    //     ? "https://em-frontend-dev-get.airtrfx.com/ssr?modules="
    //     : "https://em-frontend-get.airtrfx.com/ssr?modules=";


    const URLStart = localURL
      ? 'http://localhost:3000/ssr?modules='
      : env === 'dev'
        ? 'https://fc-services-api-dev.securitytrfx.com/ssr?modules='
        : 'https://fc-services-api.securitytrfx.com/ssr?modules='

    React.useEffect(() => {
        const CODE_VALUE = ssrString(
            currentVersion,
            moduleName,
            componentName,
            env,
            localURL,
            react16,
            context,
            style
        );
        setValue(URLStart + CODE_VALUE);
        setIframeValue({ start: URLStart, value: CODE_VALUE });
        // eslint-disable-next-line
    }, [env, localURL, react16, context, componentName, style, selectedTenant]);

    React.useEffect(() => {
        setStyle(STYLES_URLS(selectedTenant.code)[1].value);
    }, [selectedTenant]);

    const themeVersion = prod[prod.length - 1].versions.meta.major;

    if (!value || !style) return null;

    return (
        <div className="block">
            <button
                className={styledButton}
                onClick={() => setLocalURL(!localURL)}
            >
                {!localURL ? "Use local URL" : "Remove local URL"}
            </button>
            <button
                className={styledButton}
                onClick={() => setReact16(!react16)}
            >
                {!react16 ? "Use React 16" : "Remove React 16"}
            </button>
            <div className="relative">
                <Copy value={value} copied={copied} setCopied={setCopied} />
                <CodeMirror
                    value={value}
                    options={options}
                    autoCursor={false}
                    onChange={(editor, data, value) => {
                        setValue(value);
                        setCopied(false);
                    }}
                />
            </div>
            <StylesHints
                list={STYLES_URLS(selectedTenant.code, themeVersion)}
                setStyle={setStyle}
                style={style}
                version={themeVersion}
            />
        </div>
    );
}
