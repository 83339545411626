import React, { useState, useEffect } from "react";
import { TopBarHome } from "components";
import { useService } from "../../hooks";
import { substractDays,formatNumber } from "helpers";
import PiechartCmp from "components/PieChart";
import { Loading } from "components/auth";
import DebouncedSelect from "components/DebouncedSelect";

const Services = ({fetchUrl, fetchHeader,clientRequestPath="ssr"}) => {
    let transformedClientRequestPath = ''
    const [dropdownDate, setDropdownDate] = useState("");
    const [dateTime, setDateTime] = useState({
        start: '',
        end: ''
    })

    const setTheState = (days) => {
        setDateTime(() => ({
            start: substractDays(days), 
            end: substractDays(0), 
        }));
    }

    useEffect(() => {
        if (dropdownDate === "7") {
            setTheState(7)
        } else if (dropdownDate === "15") {
            setTheState(15)
        } else if (dropdownDate === "30") {
            setTheState(30)
        } else {
            setTheState(1)
        }
    }, [dropdownDate]);

    if(clientRequestPath.toLowerCase()==="labels" || clientRequestPath.toLowerCase()==="settings")
    transformedClientRequestPath=`integration/${clientRequestPath.toLowerCase()}/v1`

    else if(clientRequestPath.toLowerCase()==="placementsettings" || clientRequestPath.toLowerCase()==="config")
        transformedClientRequestPath=`${clientRequestPath.toLowerCase()==="placementsettings" ? 'placementSettings' : 'config'}/v1`

    else transformedClientRequestPath = clientRequestPath

    const { service, loading, error} = useService({
        fetchBody: {
            datetimeStart: dateTime.start,
            datetimeEnd: dateTime.end,
            clientRequestPath: transformedClientRequestPath
        },
        fetchUrl,
        fetchHeader
    });

    

    const sortedStatusCode = service?.edgeStatusCodes?.sort((a, b) => {
        return a.status - b.status;
    });

    return (
        <>
            <TopBarHome />
            <div className="rounded-lg bg-neutral-100 p-6 text-neutral-700 shadow-lg dark:bg-neutral-600 dark:text-neutral-200 dark:shadow-black/30 h-full min-h-screen">
                <h2 className="mb-5 text-3xl font-semibold text-blue-800">
                     {`${clientRequestPath.toLowerCase() === 'placementsettings' ? 'PLACEMENT SETTINGS': clientRequestPath.toUpperCase()} - Cloudflare Analytics`}
                </h2>
                <div className="flex w-full h-full justify-end pt-4 pb-8 pl-4">
                    <DebouncedSelect
                        onSelect={setDropdownDate}
                        options={[
                            {
                                elemName: "Previous 24 hours",
                                elemVal: "24",
                            },
                            {
                                elemName: "Previous 7 days",
                                elemVal: "7",
                            },
                            {
                                elemName: "Previous 15 days",
                                elemVal: "15",
                            },
                            {
                                elemName: "Previous 30 days",
                                elemVal: "30",
                            },
                        ]}
                    />
                </div>
                {loading &&  
                    <div className="flex w-full min-h-full justify-center items-center">
                        <div className="text-dark-blue text-2xl font-medium text-blue-800 lg:pt-40">
                            <Loading containerClass="w-full h-full" text="Retrieving data. Please wait..." />
                        </div>
                    </div>}

                {error && !service && (
                    <div className="flex flex-col w-full min-h-full justify-center items-center space-y-8">
                        <div className="text-dark-blue text-2xl font-medium text-red-500 lg:pt-40">
                            {error}
                        </div>
                        <button onClick={() => setTheState(dropdownDate)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Reload page</button>
                    </div>
                )}
                {!error && !loading && service && Array.isArray(service?.edgeStatusCodes) && service?.edgeStatusCodes?.length > 0 && (
                    <>
                        <div className="w-full pb-6 space-x-2">
                                <span className="text-2xl font-semi-bold text-black">{`Total of requests:`}</span>
                                <span className="text-2xl font-bold text-blue-800">
                                    {formatNumber(service?.total) || 0}
                                </span>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 items-center justify-center pb-4">
                                {Array.isArray(service?.cacheStatuses) &&
                                    service?.cacheStatuses?.length > 0 && (
                                        <PiechartCmp
                                            chartTitleClassName="w-full text-center text-blue-800 text-xl font-bold"
                                            chartTitle="Cache Status"
                                            dataList={service.cacheStatuses}
                                            dataKey="count"
                                            nameKey="status"
                                            fillPie="#2c5282"
                                        />
                                    )}
                                {Array.isArray(service?.countries) &&
                                    service?.countries?.length > 0 && (
                                        <PiechartCmp
                                            chartTitleClassName="w-full text-center text-black text-xl font-bold"
                                            chartTitle="Countries"
                                            dataList={service.countries}
                                            dataKey="count"
                                            nameKey="country"
                                            fillPie="#fbbf24"
                                        />
                                    )}
                                {Array.isArray(service?.dataCenters) &&
                                    service?.dataCenters?.length > 0 && (
                                        <PiechartCmp
                                            chartTitleClassName="w-full text-center text-red-500 text-xl font-medium font-bold"
                                            chartTitle="Data Centers"
                                            dataList={service.dataCenters}
                                            dataKey="count"
                                            nameKey="status"
                                            fillPie="#ef4444"
                                        />
                                    )}
                                
                        </div>
                        <div className="flex flex-col w-full justify-center bg-gray-100 shadow-md rounded-lg p-6 space-y-4">
                            <div className="w-full flex flex-col space-y-5">
                                <div className="w-full text-center text-black text-xl font-bold">Status Code</div>
                                    <div className="flex space-x-10 w-full">
                                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-10 items-center justify-center">
                                        {sortedStatusCode.map(({status, count}, index) => (
                                            <div key={index} className={`flex-col border-4 ${status === 200 ? 'border-green-700' : 'border-orange-600' } rounded-lg p-8`}>
                                                <div className={`${status === 200 ? 'text-green-600' : 'text-orange-500'} text-4xl font-bold text-center`}>{status}</div>
                                                <div className="text-md font-bold text-center">{`${formatNumber(count)} requests`}</div>
                                                <div className="text-md font-bold">{`${((count*100)/service.total).toFixed(1)}% of total requests`}</div>
                                            </div>
                                            ))}
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </>
                )}
                    {/* Commented in Case  is necessary in the future*/}
                    {/* <p>{JSON.stringify(ssr)}</p> */}
                {/* </RenderIf> */}
            </div>
        </>
    );
}

export default Services;