import React, { useState } from "react";
import {
    SideBar,
    AirComponents,
    Home,
    ServiceComp,
    Analytics
} from "components";
import "./App.css";
import { Loading, Unauthenticated } from "components/auth";
import { useGoogleAuthContext } from "context/googleAuthContext";
import { servicesFetchData } from './servicesFetchData';

export default function App() {
    const { currentUser, resLogin } = useGoogleAuthContext();
    const [sidebar, setSidebar] = useState("dashboard");

    if (!resLogin.loaded) return <Loading containerClass="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-gray-300"/>;
    if (!currentUser) return <Unauthenticated />;

    return (
        <>
            <SideBar setSidebar={setSidebar} sidebar={sidebar} />
            <div className="main">
                {(() => {
                    switch (sidebar) {
                        case "components":
                            return <AirComponents />;
                        case "analytics":
                            return <Analytics />;
                        case "ssr":
                        case "labels":
                        case "placementSettings":
                        case "config":
                        case "settings":
                        case "globalSettings":
                            return <ServiceComp fetchUrl={servicesFetchData.cloudflare.url} clientRequestPath={sidebar} fetchHeader={servicesFetchData.cloudflare.header}/>;
                        default:
                            return <Home />;
                    }
                })()}
            </div>
        </>
    );
}
