import React from "react";
import { useModuleContext } from "context/moduleContext";
import { useRegistryContext } from "context/registryContext";

export default function Button({ label = "", name }) {
    const { handleSetModule, module } = useModuleContext();
    const { registry } = useRegistryContext();

    function handleClick() {
        const m = registry.find((r) => r.name === name);
        handleSetModule(m);
    }

    const isActive = module?.name === name;

    return (
        <button
            className={`focus:outline-none font-bold capitalize hover:text-blue-400 animate whitespace-no-wrap ${
                isActive ? "text-blue-400" : ""
            }`}
            onClick={handleClick}
        >
            {label}
        </button>
    );
}
