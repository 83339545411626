import { filterNaNVersion } from "helpers";

export default function mapVersions(obj) {
    let array = Object.keys(obj);

    array = array
        .map((a) =>
            a
                .split(".")
                .map((n) => +n + 100000)
                .join(".")
        )
        .sort()
        .map((a) =>
            a
                .split(".")
                .map((n) => +n - 100000)
                .join(".")
        )
        .reverse();

    return filterNaNVersion(array);
}
